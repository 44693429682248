import { fetchProducts } from '../products/actions'
import { findProject, createProject, fetchProjectMapping } from '../projects/actions'

export function initNewProject() {
  return async dispatch => {
    const item = await dispatch(
      createProject({
        name: '',
      })
    )

    dispatch({
      type: 'RPM/EDIT_PROJECT/PROJECT_FETCHED',
      id: item.id,
    })

    return item
  }
}

export function initProject(id) {
  return async dispatch => {
    const project = await dispatch(findProject(id, ['media', 'configuration_categories']))
    const mapping = await dispatch(fetchProjectMapping(id))

    // add mapping products to state
    if (mapping && mapping.length > 0) {
      const mappingProductIds = mapping.map(mappingItem => mappingItem.product_id)
      await dispatch(fetchProducts({ _id: mappingProductIds }, ['media']))
    }

    dispatch({
      type: 'RPM/EDIT_PROJECT/PROJECT_FETCHED',
      id: project.id,
    })

    return { project, mapping }
  }
}

export function changeProjectAttribute(attribute, value, isValid = true) {
  return async dispatch => {
    dispatch({
      type: 'RPM/EDIT_PROJECT/PROJECT_ATTRIBUTES_CHANGED',
      attributes: {
        [attribute]: {
          value,
          isValid,
        },
      },
    })
  }
}

export function resetProjectAttribute(attribute) {
  return async dispatch => {
    dispatch({
      type: 'RPM/EDIT_PROJECT/PROJECT_ATTRIBUTES_RESET',
      attribute,
    })
  }
}
