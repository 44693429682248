import _ from 'lodash'
import moment from 'moment'
import ProjectApi from '../../api/projects'
import ConfigurationCategoryApi from '../../api/configurationCategories'
import ProjectMappingApi from '../../api/projectMapping'
import ModelChangeItemsApi from '../../api/modelChangeItems'
import {
  addOrUpdateModelChangeItemToComparisonBoard,
  addProductToComparisonBoard,
  removeModelChangeItemFromComparisonBoard,
  removeProductFromComparisonBoard,
} from '../../services/comparisonBoard'
import { selectConfigurationCategoryProductTemplate, selectProjectMappingByConfigurationCategory } from './selectors'

// Projects actions
export function createProject(attributes) {
  return async dispatch => {
    const item = await ProjectApi.create(attributes)

    dispatch({
      type: 'RPM/PROJECTS_ADDED',
      projects: {
        [item.id]: { ...item },
      },
    })

    dispatch({
      type: 'RPM/PROJECT_CREATED',
      project: item,
    })

    return item
  }
}

export function findProject(id, includes = []) {
  return async dispatch => {
    const item = await ProjectApi.find(id, includes)

    dispatch({
      type: 'RPM/PROJECTS_ADDED',
      projects: {
        [item.id]: { ...item },
      },
    })

    return item
  }
}

export function updateProject(id, attributes) {
  return async dispatch => {
    const item = await ProjectApi.update(id, attributes)

    dispatch({
      type: 'RPM/PROJECT_UPDATED',
      id,
      attributes: { ...item },
    })

    return item
  }
}

export function deleteProject(id) {
  return async dispatch => {
    await ProjectApi.delete(id)
    dispatch({
      type: 'RPM/PROJECT_REMOVED',
      id,
    })
  }
}

// Configuration categories actions
export function createConfigurationCategory(attributes) {
  return async dispatch => {
    // temporary added to avoid api error
    if (attributes && !attributes.comparison_board) delete attributes.comparison_board

    const item = await ConfigurationCategoryApi.create(attributes)

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_CREATED',
      item,
    })

    return item
  }
}

export function updateConfigurationCategory(id, attributes) {
  return async dispatch => {
    const item = await ConfigurationCategoryApi.update(id, attributes)

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item,
    })

    return item
  }
}

export function deleteConfigurationCategory(configurationCategoryId, projectId) {
  return async dispatch => {
    await ConfigurationCategoryApi.delete(configurationCategoryId)
    await dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_REMOVED',
      configurationCategoryId,
      projectId,
    })
    const mapping = await ProjectMappingApi.get(projectId)

    dispatch({
      type: 'RPM/PRODUCTS_MAPPED_TO_PROJECT',
      projectId,
      added: mapping,
    })
  }
}

// Projects mapping actions
export function fetchProjectMapping(projectId) {
  return async dispatch => {
    const mapping = await ProjectMappingApi.get(projectId)

    dispatch({
      type: 'RPM/PRODUCTS_MAPPED_TO_PROJECT',
      projectId,
      added: mapping,
    })

    return mapping
  }
}

/**
 * Update the associations of products with the related project and configuration category.
 * It also updates the products data in the configuration category at the same time.
 * Also update the associations of products with the related project if configurationCategoryId is null
 *
 * @param {*} productIds
 * @param {*} projectId
 * @param {*} configurationCategoryId
 * @returns
 */
export function mapProductsToProject(
  productIds,
  projectId,
  configurationCategoryId = null,
  preventUpdateComparisonBoard = false
) {
  return async (dispatch, getState) => {
    let state = getState()

    const project = state.renaultProjectMode.projects.entities[projectId]
    const configurationCategory = _.find(
      project.configuration_categories,
      _item => _item.id === configurationCategoryId
    )
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)
    const oldTemplate = selectConfigurationCategoryProductTemplate(state, projectId, configurationCategoryId)

    const currentMappings = _.filter(
      state.renaultProjectMode.projects.mapping[projectId],
      value => value.configuration_category_id === configurationCategoryId
    )

    const productIdsToAdd = _.filter(productIds, id => {
      let found = false
      _.each(currentMappings, value => {
        if (value.product_id === id) {
          found = true
        }
      })

      return !found
    })

    const mappingsToDelete = _.filter(currentMappings, value => !_.includes(productIds, value.product_id))
    const mappingsToAdd = _.map(productIdsToAdd, productId => {
      return {
        product_id: productId,
        configuration_category_id: configurationCategoryId,
        project_id: projectId,
      }
    })

    const promises = []

    _.each(mappingsToAdd, item => {
      // Update comparison board data
      const product = state.renaultProjectMode.products.entities[item.product_id]

      if (clonedConfigurationCategory && !preventUpdateComparisonBoard)
        addProductToComparisonBoard(clonedConfigurationCategory, product)

      // Creation promises
      promises.push(
        new Promise((resolve, reject) => {
          ProjectMappingApi.create(projectId, item)
            .then(() => resolve())
            .catch(error => reject(error))
        })
      )
    })

    _.each(mappingsToDelete, item => {
      // Update comparison board data
      if (clonedConfigurationCategory && !preventUpdateComparisonBoard)
        removeProductFromComparisonBoard(clonedConfigurationCategory, item.product_id)

      // Deletion promises
      promises.push(
        new Promise((resolve, reject) => {
          ProjectMappingApi.delete(projectId, item.product_id, configurationCategoryId)
            .then(() => resolve())
            .catch(error => reject(error))
        })
      )
    })

    dispatch({
      type: 'RPM/PRODUCTS_MAPPED_TO_PROJECT',
      projectId,
      added: mappingsToAdd,
      removed: mappingsToDelete,
    })

    if (clonedConfigurationCategory)
      await dispatch({
        type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
        item: clonedConfigurationCategory,
      })

    // TODO extract to another specific action
    if (clonedConfigurationCategory && !preventUpdateComparisonBoard) {
      state = getState()
      const newTemplate = selectConfigurationCategoryProductTemplate({ ...state }, projectId, configurationCategoryId)

      if (oldTemplate && (!newTemplate || (newTemplate && oldTemplate.id !== newTemplate.id))) {
        // If the template changes or any new product is selected, remove old templates default model change items
        const oldModelChangeItems = await ModelChangeItemsApi.get({ template_id: oldTemplate.id })
        _.each(oldModelChangeItems, _item =>
          removeModelChangeItemFromComparisonBoard(clonedConfigurationCategory, _item.id)
        )
      }

      if (newTemplate) {
        // Set default model change items on comparison board
        const defaultModelChangeItems = await ModelChangeItemsApi.get({ template_id: newTemplate.id })
        _.each(defaultModelChangeItems, _item =>
          addOrUpdateModelChangeItemToComparisonBoard(clonedConfigurationCategory, { ..._item, default: true })
        )
      }
    }

    if (clonedConfigurationCategory)
      await dispatch({
        type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
        item: clonedConfigurationCategory,
      })

    await Promise.all(promises)

    if (clonedConfigurationCategory) {
      console.log(clonedConfigurationCategory.comparison_board)
      await ConfigurationCategoryApi.update(configurationCategoryId, {
        comparison_board: clonedConfigurationCategory.comparison_board,
      })
    }
  }
}

/**
 * Remove an association between a product and the related project and configuration category.
 * It also updates the products data in the configuration category at the same time.
 * Remove also association between a product and related project without any configuration category relation
 *
 * @param {*} productId
 * @param {*} projectId
 * @param {*} configurationCategoryId
 * @returns
 */
export function removeProductFromProject(productId, projectId, configurationCategoryId = null) {
  return async (dispatch, getState) => {
    const state = getState()

    const currentMappings = _.filter(
      state.renaultProjectMode.projects.mapping[projectId],
      value => value.configuration_category_id === configurationCategoryId
    )

    const productIdsFiltered = _.map(
      _.filter(currentMappings, item => item.product_id !== productId),
      item => item.product_id
    )

    dispatch(mapProductsToProject(productIdsFiltered, projectId, configurationCategoryId))
  }
}

/**
 * Remove a product from all conf categories in its project related with it.
 *
 * @param {*} productId
 * @param {*} projectId
 * @returns
 */

export function removeProductFromAllConfCategories(productId, projectId) {
  return async (dispatch, getState) => {
    const state = getState()

    const mappingWithSelectedProduct = _.filter(
      state.renaultProjectMode.projects.mapping[projectId],
      value => value.product_id === productId
    )

    const configurationCategoryIds = _.map(mappingWithSelectedProduct, item => item.configuration_category_id)

    _.each(configurationCategoryIds, configurationCategoryId => {
      const currentMappings =
        _.filter(
          state.renaultProjectMode.projects.mapping[projectId],
          value => value.configuration_category_id === configurationCategoryId
        ) || []

      const productIdsFiltered = _.map(
        _.filter(currentMappings, item => item.product_id !== productId),
        item => item.product_id
      )

      dispatch(mapProductsToProject(productIdsFiltered, projectId, configurationCategoryId))
    })
  }
}

/**
 * Duplicate a configuration category and the related mapping
 *
 * @param {*} originalConfigurationCategory
 * @returns
 */
export function duplicateConfigurationCategory(originalConfigurationCategory, attributes = {}) {
  return async (dispatch, getState) => {
    const state = getState()
    // temporary added to avoid api error
    if (originalConfigurationCategory && !originalConfigurationCategory.comparison_board)
      originalConfigurationCategory.comparison_board = []

    const projectId = originalConfigurationCategory.project_id

    const mapping = selectProjectMappingByConfigurationCategory(state, projectId, originalConfigurationCategory.id)

    const newDueDate = attributes.due_date ?? originalConfigurationCategory.due_date

    const duplicate = await dispatch(
      createConfigurationCategory({
        name: attributes.name ?? originalConfigurationCategory.name,
        comparison_board: originalConfigurationCategory.comparison_board,
        due_date: newDueDate ? moment(newDueDate).format('YYYY-MM-DD') : null,
        project_id: originalConfigurationCategory.project_id,
        type: attributes.type ?? originalConfigurationCategory.type,
      })
    )

    const productsIds = _.map(mapping, map => map.product_id)

    await dispatch(mapProductsToProject(productsIds, projectId, duplicate.id, true))
  }
}

export function addProductsToProject(productIds, projectId) {
  return async (dispatch, getState) => {
    const state = getState()

    const currentMappings = _.filter(
      state.renaultProjectMode.projects.mapping[projectId],
      value => value.configuration_category_id === null
    )

    const productIdsToAdd = _.filter(productIds, id => {
      let found = false
      _.each(currentMappings, value => {
        if (value.product_id === id) {
          found = true
        }
      })

      return !found
    })

    const mappingsToAdd = _.map(productIdsToAdd, productId => {
      return {
        product_id: productId,
        configuration_category_id: null,
        project_id: projectId,
      }
    })

    const promises = []

    _.each(mappingsToAdd, item => {
      // Creation promises
      promises.push(
        new Promise((resolve, reject) => {
          ProjectMappingApi.create(projectId, item)
            .then(() => resolve())
            .catch(error => reject(error))
        })
      )
    })

    dispatch({
      type: 'RPM/PRODUCTS_MAPPED_TO_PROJECT',
      projectId,
      added: mappingsToAdd,
    })

    await Promise.all(promises)
  }
}
