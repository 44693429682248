import _ from 'lodash'
import ProductsApi from '../api/products'
import {
  RPM_COMPARISON_BOARD_OPTION_BEST_WORST,
  RPM_COMPARISON_BOARD_OPTION_DIFFERENCES,
  RPM_COMPARISON_BOARD_OPTION_FULL_DATA,
  RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE,
} from '../consts/comparisonBoard'
import { toFixed1IfDecimal } from '../../../../va-corejs-v3/utils'
import {
  SYNC_ACTION_CREATE_COMMENTS,
  SYNC_ACTION_DELETE_COMMENT,
  SYNC_ACTION_UPDATE_COMMENTS,
  SYNC_ACTION_UPDATE_PRODUCT,
  SYNC_ACTION_UPDATE_SCORING_TREE,
} from '../../../../va-corejs-v3/sync/consts'
import syncQueue from '../../../../va-corejs-v3/sync/syncQueue'
import storage from '../../../../va-corejs-v3/storage'
import storageMap from '../../../../va-corejs-v3/storage/storageMap'
import { normalizeComment } from '../../../../va-corejs-v3/utils/dataNormalizers'
import normalizeDirectionMap from '../../../../va-corejs-v3/utils/normalizeDirectionMap'

const comparisonBoardProductTemplate = (product, args = {}) => {
  return {
    id: product.id,
    product_id: product.id, // Just an alias
    template_id: product.template_id,
    props: product.props,
    score_panel: product.score_panel,
    score_digital: product.score_digital,
    color: _.get(args, 'color', null),
    board_locked: _.get(args, 'board_locked', true),
    order: _.get(args, 'order', 0),
    reference: _.get(args, 'reference', false),
    nodes: product.nodes,
    is_configuration: product.is_configuration,
  }
}

const comparisonBoardOptionsTemplate = (options = {}) => {
  return {
    [RPM_COMPARISON_BOARD_OPTION_BEST_WORST]: _.get(options, RPM_COMPARISON_BOARD_OPTION_BEST_WORST, false),
    [RPM_COMPARISON_BOARD_OPTION_FULL_DATA]: _.get(options, RPM_COMPARISON_BOARD_OPTION_FULL_DATA, false),
    [RPM_COMPARISON_BOARD_OPTION_DIFFERENCES]: _.get(options, RPM_COMPARISON_BOARD_OPTION_DIFFERENCES, false),
    [RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE]: _.get(options, RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE, false),
  }
}

const comparisonBoardTemplate = () => {
  return {
    options: comparisonBoardOptionsTemplate(),
    products_data: [],
    model_change_data: {},
    model_change_items: [],
    edited_nodes: {},
  }
}

export const addProductToComparisonBoard = (configurationCategory, product, args = {}) => {
  // Initialize values if not present
  if (!configurationCategory.comparison_board) {
    configurationCategory.comparison_board = comparisonBoardTemplate()
  }

  // Initialize values if not present
  if (!configurationCategory.comparison_board.products_data) {
    configurationCategory.comparison_board.products_data = []
  }

  configurationCategory.comparison_board.products_data.push(
    comparisonBoardProductTemplate(product, {
      ...args,
      order: configurationCategory.comparison_board.products_data.length + 1,
    })
  )
}

export const updateProductInComparisonBoard = (configurationCategory, productId, data = {}) => {
  configurationCategory.comparison_board.products_data = _.map(
    configurationCategory.comparison_board.products_data,
    _product => {
      if (_product.product_id !== productId) {
        return _product
      }

      const newProduct = {
        ..._product,
        ...data,
      }

      if (data.nodes) {
        newProduct.nodes = _.map(newProduct.nodes, _node => {
          const newNode = _.find(data.nodes, _n => _n.id === _node.id)
          return newNode ? { ..._node, ...newNode } : _node
        })
      }

      return newProduct
    }
  )
}

export const removeProductFromComparisonBoard = (configurationCategory, productId) => {
  const { comparison_board: comparisonBoard } = configurationCategory

  if (comparisonBoard && comparisonBoard.products_data) {
    _.remove(comparisonBoard.products_data, _item => _item.product_id === productId)
  }
}

export const updateOptionInComparisonBoard = (configurationCategory, option, value) => {
  // Initialize values if not present
  if (!configurationCategory.comparison_board) {
    configurationCategory.comparison_board = comparisonBoardTemplate()
  }

  // Initialize values if not presents
  if (!configurationCategory.comparison_board.options) {
    configurationCategory.comparison_board.options = comparisonBoardOptionsTemplate()
  }

  const booleanValue = Boolean(value)

  switch (option) {
    case RPM_COMPARISON_BOARD_OPTION_BEST_WORST:
      configurationCategory.comparison_board.options.best_worst = booleanValue

      // Options mutually exclusive must be shutted down
      if (booleanValue) {
        configurationCategory.comparison_board.options.differences = false
        configurationCategory.comparison_board.options.full_data = false
        configurationCategory.comparison_board.options.model_change = false
      }

      break
    case RPM_COMPARISON_BOARD_OPTION_FULL_DATA:
      configurationCategory.comparison_board.options.full_data = booleanValue

      // Options mutually exclusive must be shutted down
      if (booleanValue) {
        configurationCategory.comparison_board.options.best_worst = false
        configurationCategory.comparison_board.options.differences = false
        configurationCategory.comparison_board.options.model_change = false
      }

      break
    case RPM_COMPARISON_BOARD_OPTION_DIFFERENCES:
      configurationCategory.comparison_board.options.differences = booleanValue

      // Options mutually exclusive must be shutted down
      if (booleanValue) {
        configurationCategory.comparison_board.options.best_worst = false
        configurationCategory.comparison_board.options.full_data = false
        configurationCategory.comparison_board.options.model_change = false
      }

      break
    case RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE:
      configurationCategory.comparison_board.options.model_change = booleanValue

      // Options mutually exclusive must be shutted down
      if (booleanValue) {
        configurationCategory.comparison_board.options.best_worst = false
        configurationCategory.comparison_board.options.full_data = false
        configurationCategory.comparison_board.options.differences = false
      }

      break
    default:
    // Do Nothing
  }
}

export const resetEditedNodesInComparisonBoard = (configurationCategory, productId) => {
  // Initialize values if not present
  if (!configurationCategory.comparison_board) {
    configurationCategory.comparison_board = comparisonBoardTemplate()
  }

  // Initialize values if not presents
  if (!configurationCategory.comparison_board.edited_nodes) {
    configurationCategory.comparison_board.edited_nodes = {}
  }

  _.unset(configurationCategory.comparison_board.edited_nodes, productId)
}

export const updateEditedNodeInComparisonBoard = (configurationCategory, node) => {
  // Initialize values if not present
  if (!configurationCategory.comparison_board) {
    configurationCategory.comparison_board = comparisonBoardTemplate()
  }

  // Initialize values if not presents
  if (!configurationCategory.comparison_board.edited_nodes) {
    configurationCategory.comparison_board.edited_nodes = {}
  }

  // Normalize data to an object
  if (Array.isArray(configurationCategory.comparison_board.edited_nodes)) {
    configurationCategory.comparison_board.edited_nodes = Object.fromEntries(
      configurationCategory.comparison_board.edited_nodes
    )
  }

  const { comparison_board: comparisonBoard } = configurationCategory

  const values = _.has(comparisonBoard.edited_nodes, node.product_id)
    ? [...comparisonBoard.edited_nodes[node.product_id]]
    : []

  if (!values.includes(node.id)) {
    values.push(node.id)
  }

  configurationCategory.comparison_board.edited_nodes[`${node.product_id}`] = values
}

export const resetEditedNodeInComparisonBoard = (configurationCategory, node) => {
  // Initialize values if not present
  if (!configurationCategory.comparison_board) {
    configurationCategory.comparison_board = comparisonBoardTemplate()
  }

  // Initialize values if not presents
  if (!configurationCategory.comparison_board.edited_nodes) {
    configurationCategory.comparison_board.edited_nodes = {}
  }

  const { comparison_board: comparisonBoard } = configurationCategory

  if (_.has(comparisonBoard.edited_nodes, node.product_id)) {
    const values = [...comparisonBoard.edited_nodes[node.product_id]]
    _.pull(values, node.id)

    if (values.length > 0) {
      configurationCategory.comparison_board.edited_nodes[node.product_id] = values
    } else {
      _.unset(configurationCategory.comparison_board.edited_nodes, node.product_id)
    }
  }
}

export const bestWorstLevels = (nodeDefinitionId, products, scoreSet = null) => {
  const scores = {}

  _.each(products, product => {
    const { nodes, product_id: id } = product
    const node = _.find(nodes, _n => _n.node_definition_id === nodeDefinitionId)
    const score = scoreSet === null ? toFixed1IfDecimal(node.score) : toFixed1IfDecimal(node[scoreSet]?.score) ?? 0

    if (!_.has(scores, score)) {
      scores[score] = {
        score: null,
        ids: [],
      }
    }

    scores[score].score = score
    scores[score].ids.push(id)
  })

  const sortedScores = _.sortBy(scores, 'score')
  const stepLength = toFixed1IfDecimal(100 / (sortedScores.length - 1))
  const levels = {}

  _.each(sortedScores, (value, index) => {
    const level = index === 0 ? 0 : stepLength * index

    _.each(value.ids, _id => {
      levels[_id] = level
    })
  })

  return levels
}

export const modelChangeLevels = (nodeDefinitionId, products, modelChangeItemId) => {
  const scores = {}

  _.each(products, product => {
    const { nodes, product_id: id } = product

    const node = _.find(nodes, _n => _n.node_definition_id === nodeDefinitionId)
    const scoreValue =
      _.find(node?.model_change, _mci => _mci.model_change_item_id === modelChangeItemId)?.normalized_score ?? 0
    const score = toFixed1IfDecimal(scoreValue)

    if (!_.has(scores, score)) {
      scores[score] = {
        score: null,
        ids: [],
      }
    }

    scores[score].score = score
    scores[score].ids.push(id)
  })

  const sortedScores = _.sortBy(scores, 'score')
  const stepLength = toFixed1IfDecimal(100 / (sortedScores.length - 1))
  const levels = {}

  _.each(sortedScores, (value, index) => {
    const level = index === 0 ? 0 : stepLength * index

    _.each(value.ids, _id => {
      levels[_id] = level
    })
  })

  return levels
}

export const unmapNodeDefinitionToModelChangeItem = (configurationCategory, modelChangeId, nodeDefinitionId) => {
  if (_.has(configurationCategory, `comparison_board.model_change_data[${modelChangeId}]`)) {
    // remove
    _.remove(configurationCategory.comparison_board.model_change_data[modelChangeId], _id => _id === nodeDefinitionId)
  }
}

export const mapNodeDefinitionToModelChangeItem = (configurationCategory, modelChangeId, nodeDefinitionId) => {
  if (
    Array.isArray(configurationCategory.comparison_board.model_change_data) ||
    !configurationCategory.comparison_board.model_change_data
  ) {
    configurationCategory.comparison_board.model_change_data = {}
  }

  if (_.has(configurationCategory, `comparison_board.model_change_data[${modelChangeId}]`)) {
    // add
    configurationCategory.comparison_board.model_change_data[modelChangeId].push(nodeDefinitionId)
    // remove duplicates
    configurationCategory.comparison_board.model_change_data[modelChangeId] = _.uniq(
      configurationCategory.comparison_board.model_change_data[modelChangeId]
    )
  } else {
    configurationCategory.comparison_board.model_change_data[modelChangeId] = [nodeDefinitionId]
  }
}

export const addOrUpdateModelChangeItemToComparisonBoard = (configurationCategory, modelChangeItem) => {
  const { id, value, ...rest } = modelChangeItem

  if (!configurationCategory.comparison_board.model_change_items) {
    configurationCategory.comparison_board.model_change_items = []
  }

  let item = _.find(configurationCategory.comparison_board.model_change_items, _item => _item.id === id)

  if (!item) {
    configurationCategory.comparison_board.model_change_items.push({ id, value, ...rest })
  } else {
    item = { id, value, ...rest }
    configurationCategory.comparison_board.model_change_items = _.map(
      configurationCategory.comparison_board.model_change_items,
      _item => (_item.id === id ? item : _item)
    )
  }
}

export const removeModelChangeItemFromComparisonBoard = (configurationCategory, modelChangeItemId) => {
  if (!configurationCategory.comparison_board.model_change_items) {
    configurationCategory.comparison_board.model_change_items = []
  }

  const item = _.find(
    configurationCategory.comparison_board.model_change_items,
    _item => _item.id === modelChangeItemId
  )

  if (item) {
    // Remove it
    _.remove(configurationCategory.comparison_board.model_change_items, _item => _item.id === modelChangeItemId)
    delete configurationCategory.comparison_board.model_change_data[modelChangeItemId]
  }
}

export const syncComparisonBoardProduct = comparisonBoardProduct => {
  return new Promise((resolve, reject) => {
    // Sync product comments
    ProductsApi.allScoringTreeComments(comparisonBoardProduct.product_id).then(async comments => {
      const commentsToUpdate = []
      const commentsToCreate = []
      const commentsPromises = []

      _.each(comparisonBoardProduct.nodes, node => {
        const { comments: newComments } = node
        const originalComments = _.filter(comments, _c => _c.node_id === node.id)

        const originalIds = _.map(originalComments, c => c.id)
        const newIds = _.map(newComments, c => c.id)

        _.each(originalIds, _id => {
          if (newIds.includes(_id)) {
            // Updated comments that have been edited
            const newComment = _.find(newComments, _c => _c.id === _id)
            commentsToUpdate.push(normalizeComment({ ...newComment }, '', '', normalizeDirectionMap.to_server))
          } else {
            // Delete comments that have been deleted
            // batch delete doesn't exist
            commentsPromises.push(
              syncQueue.add(SYNC_ACTION_DELETE_COMMENT, {
                id: _id,
                node_id: node.id,
                product_id: comparisonBoardProduct.id,
              })
            )
          }
        })

        _.each(newComments, _c => {
          // Create new comments
          if (!originalIds.includes(_c.id)) {
            commentsToCreate.push(normalizeComment({ ..._c }, '', '', normalizeDirectionMap.to_server))
          }
        })
      })

      if (commentsToCreate.length > 0) {
        // Batch create of comments
        await syncQueue.add(SYNC_ACTION_CREATE_COMMENTS, {
          product_id: comparisonBoardProduct.id,
          comments: commentsToCreate,
        })
      }

      if (commentsToUpdate.length > 0) {
        // Batch update of comments
        await syncQueue.add(SYNC_ACTION_UPDATE_COMMENTS, {
          product_id: comparisonBoardProduct.id,
          comments: commentsToUpdate,
        })
      }
    })

    // SYnc product scoring tree
    ProductsApi.find(comparisonBoardProduct.product_id)
      .then(async _p => {
        _p.score_panel = comparisonBoardProduct.score_panel
        _p.nodes = _.keyBy(comparisonBoardProduct.nodes, 'node_definition_id')
        await storage.update(storageMap.scoring, _p)
        return _p
      })
      .then(async _p => {
        await syncQueue.add(SYNC_ACTION_UPDATE_PRODUCT, { id: _p.id })
        await syncQueue.add(SYNC_ACTION_UPDATE_SCORING_TREE, {
          product_id: _p.id,
          nodes: _p.nodes,
        })

        resolve()
      })
      .catch(error => reject(error))
  })
}

export const isProductUpdatable = product => {
  return !(!product?.is_configuration || product?.board_locked)
}
