import _ from 'lodash'
import { findProject, fetchProjectMapping } from '../projects/actions'
import { selectProjectConfigurationCategoryById } from '../projects/selectors'
import { selectComparisonBoardProducts, selectComparisonBoardProductTemplate, selectIsAllExpanded } from './selectors'
import ConfigurationCategoryApi from '../../api/configurationCategories'
import ProductsApi from '../../api/products'
import {
  mapNodeDefinitionToModelChangeItem,
  unmapNodeDefinitionToModelChangeItem,
  isProductUpdatable,
  resetEditedNodeInComparisonBoard,
  resetEditedNodesInComparisonBoard,
  syncComparisonBoardProduct,
  updateEditedNodeInComparisonBoard,
  updateOptionInComparisonBoard,
  updateProductInComparisonBoard,
  addOrUpdateModelChangeItemToComparisonBoard,
  removeModelChangeItemFromComparisonBoard,
} from '../../services/comparisonBoard'
import {
  RPM_COMPARISON_BOARD_OPTION_BEST_WORST,
  RPM_COMPARISON_BOARD_OPTION_DIFFERENCES,
  RPM_COMPARISON_BOARD_OPTION_FULL_DATA,
  RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE,
} from '../../consts/comparisonBoard'
import nodeDefinitionTypeMap from '../../../../components/scoring_tree/helper/nodeDefinitionTypeMap'
import { calculateProductScore } from '../../services/product'
import { calculateProductScoreWithModelChange } from '../../services/modelChange'
import { fetchProducts, fetchScoringTreeMedia } from '../products/actions'

const arrayMove = require('array-move')

export function initComparisonBoard(projectId, configurationCategoryId) {
  return async (dispatch, getState) => {
    await dispatch(findProject(projectId, ['media', 'configuration_categories']))
    const mapping = await dispatch(fetchProjectMapping(projectId))

    const configurationCategoryProductIds = mapping
      .filter(item => item.configuration_category_id === configurationCategoryId)
      .map(item => item.product_id)

    // Fetch products info
    if (configurationCategoryProductIds && configurationCategoryProductIds.length > 0)
      await dispatch(fetchProducts({ _id: configurationCategoryProductIds }, ['media']))

    // Fetch products scoring tree media
    _.each(configurationCategoryProductIds, id => dispatch(fetchScoringTreeMedia(id)))

    const state = getState()
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)

    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    const { comparison_board: comparisonBoard } = clonedConfigurationCategory

    await dispatch({
      type: 'RPM/COMPARISON_BOARD/INIT',
      projectId,
      configurationCategoryId,
      options: {
        [RPM_COMPARISON_BOARD_OPTION_FULL_DATA]:
          comparisonBoard?.options?.[RPM_COMPARISON_BOARD_OPTION_FULL_DATA] ?? false,
        [RPM_COMPARISON_BOARD_OPTION_BEST_WORST]:
          comparisonBoard?.options?.[RPM_COMPARISON_BOARD_OPTION_BEST_WORST] ?? false,
        [RPM_COMPARISON_BOARD_OPTION_DIFFERENCES]:
          comparisonBoard?.options?.[RPM_COMPARISON_BOARD_OPTION_DIFFERENCES] ?? false,
        [RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE]:
          comparisonBoard?.options?.[RPM_COMPARISON_BOARD_OPTION_MODEL_CHANGE] ?? false,
      },
      productsData: comparisonBoard?.products_data,
      modelChangeData: comparisonBoard?.model_change_data,
      modelChangeItems: comparisonBoard?.model_change_items,
      editedNodes: comparisonBoard?.edited_nodes,
    })

    // Expand node definition rows until the first item
    const template = selectComparisonBoardProductTemplate(getState())
    if (template) {
      const sortedNodeDefinitions = _.sortBy(_.values(template.node_definitions), '_left')
      const nodesToExpand = _.takeWhile(sortedNodeDefinitions, nd => nd.type !== nodeDefinitionTypeMap.criterion)
      dispatch({
        type: 'RPM/COMPARISON_BOARD/ROWS_EXPANDED',
        nodeDefinitionIds: _.map(nodesToExpand, _n => _n.id),
      })
    }
  }
}

export function saveComparisonBoardProducts() {
  return async (dispatch, getState) => {
    const state = getState()
    const { productsData, projectId, configurationCategoryId } = state.renaultProjectMode.comparisonBoard
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    const promises = []

    _.each(productsData, product => {
      if (isProductUpdatable(product)) {
        promises.push(
          new Promise((resolve, reject) => {
            syncComparisonBoardProduct(product)
              .then(() => {
                // Reset "edited nodes" attribute for the product is being saved
                resetEditedNodesInComparisonBoard(clonedConfigurationCategory, product.product_id)
                resolve()
              })
              .catch(error => reject(error))
          })
        )
      }
    })

    await Promise.all(promises)

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategory,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function saveComparisonBoardProduct(productId) {
  return async (dispatch, getState) => {
    const state = getState()
    const { projectId, configurationCategoryId } = state.renaultProjectMode.comparisonBoard
    const products = selectComparisonBoardProducts(state)
    const product = _.find(products, _p => _p.product_id === productId)
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    if (isProductUpdatable(product)) {
      await syncComparisonBoardProduct(product)

      // Reset "edited nodes" attribute for the product is being saved
      resetEditedNodesInComparisonBoard(clonedConfigurationCategory, productId)

      dispatch({
        type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
        item: clonedConfigurationCategory,
      })

      ConfigurationCategoryApi.update(configurationCategoryId, {
        comparison_board: clonedConfigurationCategory.comparison_board,
      })
    }
  }
}

export function resetComparisonBoardProduct(productId) {
  return async (dispatch, getState) => {
    const state = getState()

    // Get the objects needed
    const { projectId, configurationCategoryId } = state.renaultProjectMode.comparisonBoard
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)
    const template = selectComparisonBoardProductTemplate(state)
    const { modelChangeItems } = state.renaultProjectMode.comparisonBoard

    // Reset => Replace current data with the original product data
    const originalProduct = await ProductsApi.find(productId)

    // Re-run model change calc algo
    _.each(modelChangeItems, item => {
      const enabledNodeDefinitionIds = clonedConfigurationCategory.comparison_board.model_change_data[item.id]
      calculateProductScoreWithModelChange(originalProduct, template, item.id, enabledNodeDefinitionIds)
    })

    updateProductInComparisonBoard(clonedConfigurationCategory, productId, {
      nodes: originalProduct.nodes,
      score_panel: originalProduct.score_panel,
    })

    resetEditedNodesInComparisonBoard(clonedConfigurationCategory, productId)

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategory,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function expandRow(nodeDefinitionId) {
  return async dispatch => {
    dispatch({
      type: 'RPM/COMPARISON_BOARD/ROW_EXPANDED',
      nodeDefinitionId,
    })
  }
}

export function collapseRow(nodeDefinitionId) {
  return async dispatch => {
    dispatch({
      type: 'RPM/COMPARISON_BOARD/ROW_COLLAPSED',
      nodeDefinitionId,
    })
  }
}

export function expandModelChangeRow(nodeDefinitionId) {
  return async dispatch => {
    dispatch({
      type: 'RPM/COMPARISON_BOARD/ROW_MODEL_CHANGE_EXPANDED',
      nodeDefinitionId,
    })
  }
}

export function collapseModelChangeRow(nodeDefinitionId) {
  return async dispatch => {
    dispatch({
      type: 'RPM/COMPARISON_BOARD/ROW_MODEL_CHANGE_COLLAPSED',
      nodeDefinitionId,
    })
  }
}

export function sortComparisonBoardProducts(oldIndex, newIndex) {
  return async (dispatch, getState) => {
    const state = getState()
    const { productsData, projectId, configurationCategoryId, productsOrder } = state.renaultProjectMode.comparisonBoard
    const clonedProducts = _.cloneDeep(productsData)

    const newOrder = arrayMove(productsOrder, oldIndex, newIndex)

    const sortedProducts = _.map(newOrder, (id, index) => {
      const item = clonedProducts[id]
      item.order = index
      return item
    })

    // Update the configuration category data also on the server
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    _.each(sortedProducts, item => {
      updateProductInComparisonBoard(clonedConfigurationCategory, item.product_id, {
        order: item.order,
      })
    })

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategory,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function calculateComparisonBoardProductsScore() {
  return async (dispatch, getState) => {
    const state = getState()

    // Get the objects needed
    const { projectId, configurationCategoryId } = state.renaultProjectMode.comparisonBoard
    const template = selectComparisonBoardProductTemplate(state)
    const products = selectComparisonBoardProducts(state)
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    const { modelChangeItems } = state.renaultProjectMode.comparisonBoard

    // Calculate
    const promises = []
    _.each(products, product => {
      const { product_id: productId } = product

      promises.push(
        new Promise((resolve, reject) => {
          // Re-run model change calc algo
          _.each(modelChangeItems, item => {
            const enabledNodeDefinitionIds = _.get(
              clonedConfigurationCategory.comparison_board.model_change_data,
              item.id,
              []
            )
            calculateProductScoreWithModelChange(product, template, item.id, enabledNodeDefinitionIds)
          })

          calculateProductScore(product, template)
            .then(result => {
              updateProductInComparisonBoard(clonedConfigurationCategory, productId, {
                nodes: result.nodes,
                score_panel: result.score_panel,
              })
              resolve()
            })
            .catch(error => reject(error))
        })
      )
    })

    await Promise.all(promises)

    // Update the configuration category data also on the server
    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategory,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function updateComparisonBoardProductNodeScore(productId, nodeId, newScore, scoreSet = null) {
  return async (dispatch, getState) => {
    const state = getState()

    // Get the objects needed
    const { projectId, configurationCategoryId } = state.renaultProjectMode.comparisonBoard
    const template = selectComparisonBoardProductTemplate(state)
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)
    const products = selectComparisonBoardProducts(state)
    const product = _.find(products, _product => _product.product_id === productId)
    const { modelChangeItems } = state.renaultProjectMode.comparisonBoard

    // Prepare a cloned product in order to avoid conflicts
    const clonedProduct = _.cloneDeep(product)
    clonedProduct.id = productId

    // Set the new score of the node
    const node = _.find(clonedProduct.nodes, _node => _node.id === nodeId)
    if (scoreSet && node[scoreSet]) {
      node[scoreSet].score = parseFloat(newScore)
      node[scoreSet].is_enabled = true
      node[scoreSet].is_default = false
    } else {
      node.score = parseFloat(newScore)
      node.is_default = false
    }

    // Calulate new full scoring
    await calculateProductScore(clonedProduct, template)

    // Re-run model change calc algo
    _.each(modelChangeItems, item => {
      const enabledNodeDefinitionIds = clonedConfigurationCategory.comparison_board.model_change_data[item.id]
      calculateProductScoreWithModelChange(clonedProduct, template, item.id, enabledNodeDefinitionIds)
    })

    // Update the configuration category
    updateEditedNodeInComparisonBoard(clonedConfigurationCategory, node)
    updateProductInComparisonBoard(clonedConfigurationCategory, productId, {
      nodes: clonedProduct.nodes,
      score_panel: clonedProduct.score_panel,
    })

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategory,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function resetComparisonBoardProductNodeScore(productId, nodeId, scoreSet = null) {
  return async (dispatch, getState) => {
    const state = getState()
    const newScore = state.environment.config.default_score

    // Get the objects needed
    const { projectId, configurationCategoryId } = state.renaultProjectMode.comparisonBoard
    const template = selectComparisonBoardProductTemplate(state)
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)
    const products = selectComparisonBoardProducts(state)
    const product = _.find(products, _product => _product.product_id === productId)
    const { modelChangeItems } = state.renaultProjectMode.comparisonBoard

    // Prepare a cloned product in order to avoid conflicts
    const clonedProduct = _.cloneDeep(product)
    clonedProduct.id = productId

    // Set the new score of the node
    const node = _.find(clonedProduct.nodes, _node => _node.id === nodeId)
    if (scoreSet && node[scoreSet]) {
      node[scoreSet].score = parseFloat(newScore)
      node[scoreSet].is_enabled = true
      node[scoreSet].is_default = true
    } else {
      node.score = parseFloat(newScore)
      node.is_default = true
    }

    // Calulate new full scoring
    await calculateProductScore(clonedProduct, template)

    // Re-run model change calc algo
    _.each(modelChangeItems, item => {
      const enabledNodeDefinitionIds = clonedConfigurationCategory.comparison_board.model_change_data[item.id]
      calculateProductScoreWithModelChange(clonedProduct, template, item.id, enabledNodeDefinitionIds)
    })

    // Update the configuration category
    resetEditedNodeInComparisonBoard(clonedConfigurationCategory, node)
    updateProductInComparisonBoard(clonedConfigurationCategory, productId, {
      nodes: clonedProduct.nodes,
      score_panel: clonedProduct.score_panel,
    })

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategory,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function toggleComparisonBoardProductReference(productId) {
  return async (dispatch, getState) => {
    const state = getState()
    const { productsOrder, productsData, projectId, configurationCategoryId } = state.renaultProjectMode.comparisonBoard
    const clonedProducts = _.cloneDeep(productsData)

    // Product set as reference must be moved in the first position
    const productIndex = _.findIndex(productsOrder, id => id === productId)
    const newOrder = arrayMove(productsOrder, productIndex, 0)
    const sortedProducts = _.map(newOrder, (id, index) => {
      const item = clonedProducts[id]
      item.order = index
      return item
    })

    // Update the configuration category data also on the server
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    let noReferenceEnabled = true

    _.each(sortedProducts, item => {
      const newValue = item.product_id === productId ? !item.reference : false

      if (newValue) {
        noReferenceEnabled = false
      }

      updateProductInComparisonBoard(clonedConfigurationCategory, item.product_id, {
        order: item.order,
        reference: newValue,
      })
    })

    // If there is any reference product, set the "differences" option to false
    if (noReferenceEnabled) {
      updateOptionInComparisonBoard(clonedConfigurationCategory, RPM_COMPARISON_BOARD_OPTION_DIFFERENCES, false)
    }

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategory,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function updateComparisonBoardProductAttributes(productId, data = {}) {
  return async (dispatch, getState) => {
    const state = getState()
    const { projectId, configurationCategoryId } = state.renaultProjectMode.comparisonBoard

    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    updateProductInComparisonBoard(clonedConfigurationCategory, productId, data)

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategory,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function toggleComparisonBoardOption(option) {
  return async (dispatch, getState) => {
    const state = getState()
    const { options, projectId, configurationCategoryId } = state.renaultProjectMode.comparisonBoard

    if (!_.has(options, option)) {
      return
    }

    const currentValue = options[option]

    // Update the configuration category data also on the server
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    updateOptionInComparisonBoard(clonedConfigurationCategory, option, !currentValue)

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategory,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function setModelChangeItemSelectedId(id) {
  return async dispatch => {
    dispatch({
      type: 'RPM/COMPARISON_BOARD/MODEL_CHANGE_ITEM_SELECTED_UPDATE',
      id,
    })
  }
}

export function toggleExpandAll() {
  return async (dispatch, getState) => {
    const state = getState()
    const template = selectComparisonBoardProductTemplate(state)
    const isAllExpanded = selectIsAllExpanded(state)

    if (isAllExpanded === false) {
      // If expand all has been activated => expand all node definitions but leaves (criteria)
      const nodeDefinitions = _.filter(
        template.node_definitions,
        nodeDefinition => nodeDefinition._right - nodeDefinition._left > 1
      )

      dispatch({
        type: 'RPM/COMPARISON_BOARD/ROWS_EXPANDED',
        nodeDefinitionIds: _.map(nodeDefinitions, _n => _n.id),
      })
    }
  }
}

export function toggleNodeModal(nodeDefinitionId = null) {
  return async dispatch => {
    // If nodeDefinitionId is null => modal should be closed
    dispatch({
      type: 'RPM/NODE_MODAL_TOGGLED',
      nodeDefinitionId,
    })
  }
}

export function toggleModelChangeItem(nodeDefinitionId, modelChangeItemId) {
  return async (dispatch, getState) => {
    const state = getState()
    const { configurationCategoryId, projectId } = state.renaultProjectMode.comparisonBoard

    // Get the template
    const template = selectComparisonBoardProductTemplate(state)

    // Get the reference node definition
    const nodeDefinition = _.find(template.node_definitions, _nd => _nd.id === nodeDefinitionId)

    // Find all criteria children of the reference node definition:
    // if the node is a criterion => get the node itself
    // if the node is not a criterion => get all the children criteria
    const criteriaNodeDefinitions =
      nodeDefinition.type === nodeDefinitionTypeMap.criterion
        ? [nodeDefinition]
        : _.filter(
            template.node_definitions,
            _nd =>
              _nd._left > nodeDefinition._left && _nd._right < nodeDefinition._right && _nd._right - _nd._left === 1
          )

    // Get all the comparison board products
    const products = selectComparisonBoardProducts(state)

    // Get the configuration category
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    // Toggle model change item in comparison board
    let criteriaNodeDefinitionIdsToRemove = []
    let criteriaNodeDefinitionIdsToAdd = []

    // if criterion: simply toggle the criterion in model change data
    if (nodeDefinition.type === nodeDefinitionTypeMap.criterion) {
      if (
        clonedConfigurationCategory.comparison_board.model_change_data?.[modelChangeItemId]?.includes(nodeDefinitionId)
      )
        criteriaNodeDefinitionIdsToRemove = criteriaNodeDefinitions
      else criteriaNodeDefinitionIdsToAdd = criteriaNodeDefinitions
    }

    // if item: toggle all the children criterion in model change data,
    if (nodeDefinition.type === nodeDefinitionTypeMap.item) {
      // if all the children are active, deactive all the children criterion
      if (
        criteriaNodeDefinitions.every(criteria =>
          clonedConfigurationCategory.comparison_board.model_change_data?.[modelChangeItemId]?.includes(criteria.id)
        )
      )
        criteriaNodeDefinitionIdsToRemove = criteriaNodeDefinitions
      // else active all the children criterion
      else criteriaNodeDefinitionIdsToAdd = criteriaNodeDefinitions
    }

    const criteriaNodeDefinitionIdsToRemoveIds = _.map(criteriaNodeDefinitionIdsToRemove, _nd => _nd.id)
    const criteriaNodeDefinitionIdsToAddIds = _.map(criteriaNodeDefinitionIdsToAdd, _nd => _nd.id)

    if (criteriaNodeDefinitionIdsToRemoveIds?.length > 0)
      _.each(criteriaNodeDefinitionIdsToRemoveIds, _id =>
        unmapNodeDefinitionToModelChangeItem(clonedConfigurationCategory, modelChangeItemId, _id)
      )

    if (criteriaNodeDefinitionIdsToAddIds?.length > 0)
      _.each(criteriaNodeDefinitionIdsToAddIds, _id =>
        mapNodeDefinitionToModelChangeItem(clonedConfigurationCategory, modelChangeItemId, _id)
      )

    const enabledNodeDefinitionIds = clonedConfigurationCategory.comparison_board.model_change_data[modelChangeItemId]

    _.each(products, product => {
      // Run model change calc algo
      calculateProductScoreWithModelChange(product, template, modelChangeItemId, enabledNodeDefinitionIds)

      updateProductInComparisonBoard(clonedConfigurationCategory, product.product_id, {
        nodes: product.nodes,
        score_panel: product.score_panel,
      })
    })

    // Update the configuration category data also on the server
    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategory,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function updateComparisonBoardProductComment(product, node, commentId, commentEditAttr) {
  return async (dispatch, getState) => {
    const { nodes, product_id: productId } = product

    // update comment in node

    const updatedNodes = nodes.map(currNode => {
      if (currNode.id === node.id) {
        const updatedNode = { ...currNode }

        // EDIT COMMENT: find comment and update it
        if (commentId) {
          if (commentEditAttr?.body && updatedNode?.comments) {
            _.find(updatedNode.comments, { id: commentId }).body = commentEditAttr.body
          }
          if (commentEditAttr?.feedback && updatedNode?.comments)
            _.find(updatedNode.comments, { id: commentId }).feedback = commentEditAttr.feedback

          if (commentEditAttr?.locale && updatedNode?.comments)
            _.find(updatedNode.comments, { id: commentId }).locale = commentEditAttr.locale
        } else {
          // ADD COMMENT: add comment to node
          if (!updatedNode.comments) updatedNode.comments = []
          updatedNode.comments.push(commentEditAttr)
        }

        return updatedNode
      }

      return currNode
    })

    // update comment in product and board
    const data = { nodes: updatedNodes }

    const state = getState()
    const { projectId, configurationCategoryId } = state.renaultProjectMode.comparisonBoard

    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    // Update the configuration category
    updateEditedNodeInComparisonBoard(clonedConfigurationCategory, node)
    updateProductInComparisonBoard(clonedConfigurationCategory, productId, data)

    // Clone configuration category to trigger update in store
    const clonedConfigurationCategoryUpdated = _.cloneDeep(clonedConfigurationCategory)

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategoryUpdated,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}

export function updateComparisonBoardModelChangeItems(modelChangeItemsUpdated) {
  return async (dispatch, getState) => {
    const state = getState()

    const { configurationCategoryId, projectId, modelChangeItems } = state.renaultProjectMode.comparisonBoard
    const configurationCategory = selectProjectConfigurationCategoryById(state, projectId, configurationCategoryId)
    const clonedConfigurationCategory = _.cloneDeep(configurationCategory)

    // check from array of modelChangeItemsUpdated which items are added, removed and edited
    const itemsAdded = Object.values(modelChangeItemsUpdated).filter(item => !modelChangeItems[item.id])
    const itemsRemoved = Object.values(modelChangeItems).filter(item => !modelChangeItemsUpdated[item.id])
    const itemsEdited = Object.values(modelChangeItemsUpdated).filter(
      item => modelChangeItems[item.id] && modelChangeItems[item.id].value !== item.value
    )

    // TO DO: update model change items in comparison board
    itemsAdded.forEach(item => {
      addOrUpdateModelChangeItemToComparisonBoard(clonedConfigurationCategory, item)
    })

    itemsEdited.forEach(item => {
      addOrUpdateModelChangeItemToComparisonBoard(clonedConfigurationCategory, item)
    })

    itemsRemoved.forEach(item => {
      removeModelChangeItemFromComparisonBoard(clonedConfigurationCategory, item?.id)
    })

    // Clone configuration category to trigger update in store
    const clonedConfigurationCategoryUpdated = _.cloneDeep(clonedConfigurationCategory)

    dispatch({
      type: 'RPM/CONFIGURATION_CATEGORY_UPDATED',
      item: clonedConfigurationCategoryUpdated,
    })

    ConfigurationCategoryApi.update(configurationCategoryId, {
      comparison_board: clonedConfigurationCategory.comparison_board,
    })
  }
}
